<template>
  <div class="block w-64 rounded-lg shadow-md">
    <card
      icon="MapPinIcon"
      :statistic="trackedCases"
      statistic-title="Total Tracked"
    />
  </div>
</template>



<script>
import card from "@/@core/components/statistics-cards/StatisticCardHorizontal.vue"
import { mapGetters } from "vuex"
export default {
  components: {
    card
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      trackedCases: "auth/trackedCases"
    })
  }
}
</script>


f