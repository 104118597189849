<template>
  <div class="flex flex-row justify-center">
    <b-card
      no-body
      class="max-w-6xl min-h-screen p-1 lg:min-w-3xl xl:min-w-6xl"
    >
      <!-- <div class="flex flex-row ">
        
        <div
          class="w-full h-32 p-1 ml-4 bg-gray-100 border rounded-lg shadow-md "
        >
          <div class="text-5xl">Case Tracker</div>
        </div>
      </div> -->
      <div
        class="relative flex flex-row w-full h-32 bg-gray-100 rounded-lg shadow-md"
      >
        <div class="absolute -top-6 -left-6">
          <total-stat-card />
        </div>

        <div
          class="flex flex-col items-center justify-center w-full text-5xl text-center"
        >
          <div class="w-full">Case Tracker</div>
        </div>
      </div>
      <div class="flex flex-row mt-2">
        <tracker-table />
      </div>
    </b-card>
  </div>
</template>



<script>
import { BCard } from "bootstrap-vue"
import TotalStatCard from "../components/case-tracker/TotalStatCard.vue"
import TrackerTable from "../components/case-tracker/TrackerTable.vue"
export default {
  components: {
    BCard,
    TotalStatCard,
    TrackerTable
  },
  data() {
    return {}
  },
  computed: {},
  async created() {},
  methods: {}
}
</script>


