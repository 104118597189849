<template>
  <div class="w-full">
    <div class="flex flex-row justify-end w-full mt-2">
      <div class="flex flex-row pr-10">
        <div class="w-48 mr-2">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="mr-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-table
      ref="table"
      class="w-full mb-0 min-h-6xl"
      borderless
      no-local-sorting
      show-empty
      responsive
      :items="backendCall"
      :fields="fields"
      :tbody-tr-class="rowClass"
      thead-class="d-none"
      :per-page="perPage"
      :busy.sync="isBusy"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #cell(case)="data">
        <div
          :class="{
            'rounded-lg': true,
            'p-2': 2,
            'mb-3': true,
            'bg-gray-200': data.index % 2 === 0,
            border: data.index % 2 === 0,
            'shadow-md': data.index % 2 === 0,
          }"
        >
          <total-case-view
            :tracked-table="true"
            :casedata="data.item"
            @updateTrackedToggle="updateTrackedToggle"
          />
        </div>
      </template>

      <template #table-busy>
        <div
          class="
            flex flex-col
            items-center
            justify-center
            my-2
            text-center
            h-72
            text-danger
          "
        >
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>



<script>
import {
  BTable,
  BSpinner,
  BFormGroup,
  BFormSelect,
  BPagination,
} from "bootstrap-vue";
import TotalCaseView from "../total-case-view/TotalCaseView.vue";
export default {
  components: {
    BTable,
    BSpinner,
    TotalCaseView,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  props: {},

  data() {
    return {
      isBusy: false,
      heading: "DOWNLOADING",

      sortBy: "exportdate",
      sortDesc: true,

      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      initialLoad: true,

      tempTableData: {},

      // SELECTION OPTIONS

      pageOptions: [5, 7, 10, 15, { value: 100, text: "Show a lot" }],
      items: [],
      fields: [
        {
          key: "case",
          label: "Tracked Cases",
        },
      ],
    };
  },

  watch: {},
  created() {},
  methods: {
    onFiltered() {},
    async updateTrackedToggle(variable) {
      const l1 = this;

      for (const [i, value] of l1.data.entries()) {
        if (value.key == variable.tableName) {
          l1.data[i].results[variable.index].is_tracked = variable.tracked;
        }
      }
    },
    backendCall(ctx, callback) {
      const l1 = this;

      var query = {};
      var x = (ctx.currentPage - 1) * this.perPage;
      if (x < 0) {
        x = x - 1;
      }
      ctx["limit"] = ctx.perPage;
      ctx["offset"] = x;

      query["ctx"] = ctx;

      l1.$AuthAxios.instance
        .get("/api-access/case-tracker/add-remove-tracker/", {
          params: {
            trackedQuery: query,
          },
        })
        .then((res) => {
          l1.tempTableData = res.data.results;

          l1.totalRows = res.data.count;
          callback(res.data.results);
          l1.isBusy = false;
        })
        .catch((error) => {
          console.log(error);
        });
      return null;
    },

    rowClass(item, type) {
      if (!item || type !== "row") return "relative";

      if (item.index % 2 === 0) return "relative table-success";
    },
  },
};
</script>

