var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex flex-row justify-end w-full mt-2"},[_c('div',{staticClass:"flex flex-row pr-10"},[_c('div',{staticClass:"w-48 mr-2"},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Per page","label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1),_c('div',{staticClass:"mr-1"},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]),_c('b-table',{ref:"table",staticClass:"w-full mb-0 min-h-6xl",attrs:{"borderless":"","no-local-sorting":"","show-empty":"","responsive":"","items":_vm.backendCall,"fields":_vm.fields,"tbody-tr-class":_vm.rowClass,"thead-class":"d-none","per-page":_vm.perPage,"busy":_vm.isBusy,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(case)",fn:function(data){return [_c('div',{class:{
          'rounded-lg': true,
          'p-2': 2,
          'mb-3': true,
          'bg-gray-200': data.index % 2 === 0,
          border: data.index % 2 === 0,
          'shadow-md': data.index % 2 === 0,
        }},[_c('total-case-view',{attrs:{"tracked-table":true,"casedata":data.item},on:{"updateTrackedToggle":_vm.updateTrackedToggle}})],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"\n          flex flex-col\n          items-center\n          justify-center\n          my-2\n          text-center\n          h-72\n          text-danger\n        "},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }